// light theme
$base-l: hsl(56, 6%, 94%);
$text-l: hsl(216, 13%, 19%);
$overlay-l: hsl(56, 6%, 80%);

// dark theme
$base-d: hsl(216, 13%, 13%);
$text-d: hsl(56, 6%, 94%);
$overlay-d: hsl(216, 13%, 19%);

// other swatches
$navy: #2b3c55;
$orange: #F46523;
$yellow: hsl(56, 75%, 67%);
$green: hsl(96, 75%, 67%);
$cyan: hsl(176, 75%, 67%);
$blue: hsl(216, 75%, 67%);
$violet: hsl(256, 75%, 67%);
$pink: hsl(316, 75%, 67%);
$red: hsl(0, 75%, 67%);
$white: hsl(56, 2%, 98%);

// Default variable overrides
$body-bg: $base-l;
$body-color: $text-l;
$primary: $navy;
$secondary: #ffffff;
$success: $green;
$info: $orange;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$font-family-base: "Raleway", system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$link-color: $info;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

@import "../node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;1,400;1,600&family=Roboto+Mono:wght@400&display=swap");

.App {
  text-align: center;
}

strong {
  font-weight: 800;
  color: $orange;
}

.nav-link {
  color: $white;
}

#form-barrier {
  padding: 0px 2rem 0px 2rem;
  margin-bottom: 10px;
}

.instructionsText {
  font-weight: 800;
  font-size: large;
  margin: 0px;
  padding: 0 12vw;
}

.Button {
  width: calc(1% + 100px);
  padding: 10px; /* Some padding */
  margin: 5px;
  box-shadow: -1px 3px 4px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-overflow: clip;
  cursor: pointer; /* Pointer/hand icon */
  float: center; /* Float the buttons side by side */
  transition: 300ms;
  font-size: 14px;
}

.App-header {
  background-color: #74777c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

.Table {
  font-size: small;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

#eval-text {
  background-color: $white;
  color: $overlay-l;
  border: 0;
  box-shadow: -1px 3px 4px 0px rgba(0, 0, 0, 0.2);
  font-family: "Roboto Mono", monospace;
}

.ScrollTable {
  overflow-x: auto;
}

.LeftAlign {
  text-align: left;
}

.RightAlign {
  text-align: right;
}

@include media-breakpoint-down(lg) {
  .HideColumn {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .HideMoreColumn {
    display: none;
  }
}
